<template>
  <div class="policy policy_service">
    <item-title :title="{ name: '保单服务', en_1: 'POLICY', en_2: 'SERVICES' }" />
    <div class="policy-content">
      <div class="content">
        <span class="users">尊敬的用户：</span><br />
        <span class="text">
          感谢您的支持与信任，我们将竭诚为您提供优质专业的保险服务。您可通过关注“明亚保险经纪“微信公众号，或进入”明亚保险商城“官方小程序，获取更多保单服务及保险资讯。
        </span>
      </div>
      <div class="imgs">
        <div class="public_number">
          <img
            src="https://mingyaweb.oss-cn-beijing.aliyuncs.com/6757949a7c39456ebf8123530caf7176.png"
            alt="QR code"
            class="QR_code"
          />
          <div class="scan_QRcode">公众号</div>
        </div>
        <div class="mini">
          <img
            src="@/assets/img/customerService/miniProgram.png"
            alt="QR code"
            class="QR_code"
          />
          <div class="scan_QRcode">小程序</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/common/itemTitle";
export default {
  components: {
    ItemTitle,
  },
  data() {
    return {};
  },
  created() {
    this.$emit("loading", false);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.policy-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  .content {
    width: 100%;
    color: #212529;
    span {
      display: inline-block;
    }
    .users {
      margin-bottom: 5px;
    }
    .text {
      text-indent: 2em;
    }
  }
  .imgs {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .scan_QRcode {
      text-align: center;
      color: #666;
      font-size: 0.13rem;
    }
    .QR_code {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0.3rem 0 0.1rem;
    }
  }
}

@media only screen and (max-width: $moble_width) {
  .mini {
    margin-bottom: 2.2rem;
  }
  img {
    margin: 0.5rem 0.5rem 0 !important;
  }
}
</style>
